import React, { Component } from "react"
import { Link } from "gatsby"
import "../assets/css/claims.css"
import Footer from '../components/footer';
import NewsLatter from '../components/newsletter';
import ShoppingContainer from '../components/shoppingcontainer';
import Layout from "../components/layout"
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions';
import image1 from "../images/claims/claim-record.svg"
import image2 from "../images/claims/cliam-rating.svg"
import image3 from "../images/claims/claim-paid.svg"
import lineImage from "../images/line2.png"
import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"
import claimsBanner from "../images/claims/claim-bg.svg"
import SEO from "../components/seo"
import claimsTap from "../images/claims/claim-tap.svg"
import claimsMsg from "../images/claims/claim-msg.svg"
import claimsAnti from "../images/claims/humans.svg"
import claimsCall from "../images/claims/claims-call.svg"
import claimsDoller from "../images/claims/claim-doller.svg"
import booklense from "../images/booklense.svg"
import bigstack from "../images/home-big-stack.svg"
import halfstack from "../images/home-half-stack.svg"
import statvalsvg from "../images/claims/Stasts-value.svg"
import staticonsvg from "../images/claims/Stats-Icon.svg"
import PopupStacks from "../components/popupStacks";
class ClaimsPage extends Component {
    state = {
        stacksPopup:null,
    }
    componentDidMount = () => {
        
    }

    


    render() {
        const { stacksPopup } = this.state;
        return (
            <Layout layoutType="main">
                <SEO title="Servicecontract.com - Claims" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                <div className="chat-content" style={{ display: 'none' }}>
                    <div className="chat-content-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
                            <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                                <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                                    <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="chat-box">

                        <div className="chatbox-inner">
                            <div className="chat-profile">
                                <img className="" src={chatProfile} alt="" />
                            </div>
                            <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
                            <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
                        </div>
                        <img className="chat-bottom" src={chatbottom} alt="" />
                    </div>

                </div>

                {stacksPopup ?
                    <div className="success-container stackspp">
                        <div className="success-inner large">
                            <div onClick={() => this.setState({ stacksPopup: false })} className="close-btn close">
                                <img className="" src={closeChat} alt="" />
                            </div>
                            <div className="content">
                                <h2 className="head">STACKS PLANS</h2>
                                <div className="subHead">The Stacks Plan is designed to put Stacks of cash back into your pockets.</div>

                                <div className="sPdesc">
                                <div className="booklense"><img src={booklense} alt="" /></div>
                                <div className="booklenseText">OK, bear with us, we’re going to try and simplify how this works (we stink at Math!) </div>
                                </div>

                                <div className="stackspp-box">
                                <div className="stackImg">
                                    Half stacks
                                    <img className="" src={halfstack} alt="" />
                                </div>
                                <div className="stacksText">
                                    <b>Half Stacks</b> costs $5/month, and gives you back half of the money that you paid us after 36 months, less the $5/month for the Half Stacks plan and any claims paid. <br /> <b>Here’s an easy example:</b> If yourservice contract costs $45/month, and you opt for the Half Stacks plan, your total monthly payment will be $50. Over 36 months, you will pay us $1,620 ($45 x 36, remember, the $5 is not returned to you), and if you do not have any claims, we will give you Half of that money back… $810! Using the same example, let’ssay you had $500 in claims over the 36 months, we would then give you $560 back ($1,620-$500 = $1,120… Half of $1,120 is $560)
                                </div>
                                </div>


                                <div className="stackspp-box">
                                <div className="stackImg">
                                    Big stacks
                                    <img className="" src={bigstack} alt="" />
                                </div>
                                <div className="stacksText">
                                    <b>Big Stacks</b> This costs $10/month and gives you all the money back that is not used, less the $10/month. So, using that same example as in Half Stacks, if no claims were made, we’d send you $1,620, and if there were $500 in claims, we’d send you $1,120. <br />
                                    <div className="wow"><b> How’s that for fairness and transparency?!</b></div>
                                    <Link to="/vehicleInfo/" className="btn primary">Get your Price</Link>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :''}

                {/* --------------banner--------- */}
                <section className="banner claims-banner">
                    <div className="banner-inner">
                        <div className="container">
                            <h1>Our Claims Processes are Different, Here’s Why</h1>
                            <h5>Because we fancy ourselves a tech company, you can either start your claim online in your profile, or call us directly (if you’re old school).  We will quickly review the issue, make sure you get your car to a highly reputable shop, and then take it from there.</h5>
                            <h5>Oh yeah, I almost forgot… bought the Stacks Plan??  Well, any unused portion of your payment may be coming back to you if you don’t make a lot of claims. Simply track that in your profile! </h5>
                        </div>
                        <img src={claimsBanner} alt="" className="claims-banner-img" />
                    </div>
                </section>

                {/* --------------- process-work-container--------- */}

                <section className="claimRating-container hide">
                    <div className="container">
                        <div className="claim-rating">
                            <div className="box">
                                <img src={image1} alt="" />
                                <h3>3sec</h3>
                                <span>Quick claim handling</span>
                            </div>
                            <div className="box">
                                <img src={image2} alt="" />
                                <h3>4.75</h3>
                                <span>claims rating</span>
                            </div>
                            <div className="box">
                                <img src={image3} alt="" />
                                <h3>25%</h3>
                                <span>of claims paid in 3 secs</span>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="claimsWorks-container fontChange">
                    <div className="container">
                        <h3 className="title">How servicecontract.com claims actually work</h3>
                        <p className="subtitle">Seamlessly file claims from your phone - anytime, anywhere</p>

                        <div className="claimsWorks-content">
                            <div className="box box-1">
                                <img className="claimsTap" src={claimsTap} alt="" />
                                <span>Tap the Claim button in your servicecontract.com profile</span>
                            </div>
                            <div className="box box-2">
                                <img className="claimsMsg" src={claimsMsg} alt="" />
                                <span>Tell us what happened</span>
                            </div>
                            <div className="box box-3">
                                <img className="claimsAnti" src={claimsAnti} alt="" />
                                <span>We will hand your claim over to our devoted team of humans ASAP!</span>
                            </div>
                            <div className="box lastBox hide">
                                <div className="innerBox upper">
                                    <img className="claimsDoller" src={claimsDoller} alt="" />
                                    <span>If your claim is instantly <br /> approved, our AI will pay<br /> you</span>
                                </div>

                                <div className="innerBox lower">
                                    <img className="claimsCall" src={claimsCall} alt="" />
                                    <span>Otherwise, our AI hands<br /> over your claim to our<br /> devoted team of humans to<br /> handle ASAP</span>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>



                <section className="claimsStats-container">
                    <div className="container">
                        <h3 className="title">Claims Stats</h3>
                        <div className="claims-stat-img">
                            {/* <div className="claims-stat-svg">
                            </div> */}
                            <img src={statvalsvg} alt="info" />
                        </div>
                        <div className="claim-stat-term">
                            <div className="claim-stat-term-img"><img src={staticonsvg} alt="info" /></div>
                            <div className="claim-stat-term-txt">
                                Subject to terms and conditions, including exclusions, of the Vehicle Service Contract. Estimated repair costs are for illustrative purposes only 
    and are based on industry averages. Actual repair costs will depend on the vehicle make and model, type of breakdown, and other particular 
    circumstances. Terms, conditions and coverages are subject to change at any time. Not all coverages are available in all areas and coverage 
    may vary by state. Purchase of coverages is optional and is not required to obtain financing.
                            </div>
                        </div>
                    </div>
                </section>
                <PopupStacks />
                <ShoppingContainer className="bg" />
                {/* <NewsLatter /> */}
                <div className="break-section">
                    <img src={lineImage} alt="line" />
                </div>
                <Footer />
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        step2response: state.register.step2response,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ClaimsPage);
